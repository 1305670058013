import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import conversionBgImage from '../../images/ROI-bg.svg';

export const CalculatorContainer = styled.div`
  position: relative;
  min-height: 680px;
  padding: 115px 0 72px;
  overflow: hidden;
  background-color: var(--accent-color);

  @media (max-width: 768px) {
    padding: 64px 0;
  }

  &.reports {
    padding-bottom: 0;
  }

  .top-poly {
    position: absolute;
    left: -48px;
    top: -96px;
  }

  .bottom-poly {
    position: absolute;
    bottom: -96px;
    left: 0;
  }

  .right-poly {
    position: absolute;
    right: -96px;
    top: 150px;

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const HeaderStyles = styled.div`
  padding: 16px 0;
  position: relative;
  background-color: var(--white);
  transition: top 0.35s cubic-bezier(0.4, 0, 0.2, 1);

  &.sticky {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 2;
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 6px;
    width: 100%;
    background: #1c3fff;
    background: ${props => `linear-gradient(
      to right,
      #1c3fff ${(props.currentStep - 1) * 16.67}%,
      #e5e5e5 ${(props.currentStep - 1) * 16.67}%
    )`};
  }

  .top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 6px;

    & svg {
      width: 210px;
      height: 31px;
    }

    & .q-icon {
      color: #b3c1d1;
      width: 24px;
      height: 24px;
    }
  }
`;

export const StepContainer = styled.div`
  position: relative;
  min-height: 680px;
  padding: 115px 0 72px;
  overflow: hidden;
  background-color: ${props =>
    props.step === 1 ? 'var(--accent-color)' : 'rgb(249, 251, 254)'};

  @media (max-width: 768px) {
    padding: 64px 15px;
  }

  .head-wrapper {
    text-align: center;
    margin: 0 auto;
    max-width: 770px;
    position: relative;
    z-index: 2;
    margin-top: 72px;

    @media (max-width: 768px) {
      margin-top: 0;
    }

    .head {
      margin-bottom: 16px;
    }

    p.subhead {
      color: var(--subtle-text-color);
    }
  }

  .estimated {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14.4px;
    line-height: 140%;
    margin-bottom: 52px;

    .icon {
      width: 16px;
      height: 24px;
      margin-right: 8px;
    }
  }

  .top-poly {
    position: absolute;
    left: -48px;
    top: -96px;
  }

  .bottom-poly {
    position: absolute;
    bottom: -96px;
    left: 0;
  }

  .right-poly {
    position: absolute;
    right: -96px;
    top: 150px;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .step-content {
    .title-block {
      max-width: 570px;
      margin: 0 auto;
      position: relative;
      z-index: 2;
      padding: ${props => (props.step === 1 ? '32px' : '0')};
      background: ${props => (props.step === 1 ? '#FFFFFF' : 'transparent')};
      box-shadow: ${props =>
        props.step === 1 ? '0px 8px 80px rgba(106, 114, 158, 0.2)' : 'unset'};
      border-radius: 12px;
    }

    .step-label {
      font-style: normal;
      text-align: center;
      letter-spacing: 1.4px;
      text-transform: uppercase;
      margin-bottom: 16px;
      font-size: 12px;
      font-weight: 900;
      line-height: 24px;
      letter-spacing: 1.4px;
      color: var(--subtle-text-color);
    }

    .step-title {
      margin-bottom: 16px;
      font-style: normal;
      font-weight: 700;
      font-size: 28.13px;
      line-height: 35px;
      text-align: center;
      letter-spacing: -0.4px;
    }

    .sub-title {
      font-style: normal;
      font-weight: 400;
      font-size: 16.2px;
      line-height: 140%;
      text-align: center;
      margin-bottom: 16px;
      color: var(--text-color);
    }

    .action-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 16px;
      div,
      .eval-btn {
        width: 100%;
      }
    }

    .select {
      text-align: left;

      & div:first-of-type:focus {
        background-color: transparent !important;
      }

      &.is-invalid {
        & svg {
          display: none;
        }
      }
    }

    input {
      height: 48px;
      box-shadow: none;
    }

    .MuiInputBase-root {
      height: 48px;
      padding: 6px 0 6px 12px;
    }
  }

  .notice {
    font-size: 14.4px;
    line-height: 140%;
    color: var(--subtle-text-color);
    margin-bottom: 0;
    margin-top: 32px;
  }
`;

export const ModalBody = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 32px;
  width: 570px;

  @media (max-width: 768px) {
    width: auto;
    padding: 32px 16px;
  }

  .heading {
    font-style: normal;
    font-weight: 700;
    font-size: 28.13px;
    line-height: 35px;
    text-align: center;
    letter-spacing: -0.4px;
    color: #171e2a;
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .subhead {
    font-style: normal;
    font-weight: 400;
    font-size: 16.2px;
    line-height: 140%;
    text-align: center;
    color: #2c3641;
    margin-bottom: 32px;
  }

  .form-container {
    position: relative;
    z-index: 2;
    text-align: left;

    .started-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      max-width: 570px;
      background: #ffffff;

      @media (max-width: 768px) {
        padding: 32px 24px;
      }

      .form-group.required .control-label:after {
        content: '*';
        color: red;
      }

      .control-label {
        font-size: 16px;
        line-height: 19px;
      }

      input {
        border-radius: 3px;
        box-sizing: border-box;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        height: 48px;
        color: #171e2a;
        box-shadow: none;
      }

      .notice {
        font-size: 14.4px;
        line-height: 140%;
        color: var(--subtle-text-color);
        margin-bottom: 0;
      }

      .select {
        height: 48px;
        text-align: left;
        border-radius: 3px;
        box-sizing: border-box;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        box-shadow: none;

        .MuiSelect-icon {
          right: 10px;
          top: calc(50% - 13px);
          pointer-events: none;

          &.MuiSelect-iconOpen {
            transform: none;
          }
        }

        & div:first-of-type:focus {
          background-color: transparent !important;
        }

        &.is-invalid {
          & svg {
            display: none;
          }
        }
      }

      button {
        text-transform: uppercase;
        box-shadow: none;
        padding: 15px 24px;
      }
    }
  }
`;

export const ReportsContainer = styled(Container)`
  position: relative;
  min-height: 680px;
  padding: 115px 0 72px;
  background: transparent;
  z-index: 1000;

  @media (max-width: 768px) {
    width: auto;
    padding: 32px 16px;
    z-index: auto;
  }

  &.result {
    min-height: auto;

    .dots-wrapper {
      display: flex;
      justify-content: space-between;
      max-width: 670px;
      margin: 0 auto;

      .label {
        font-size: 12px;
        line-height: 24px;
        font-weight: 900;
        letter-spacing: 1.4px;
        text-transform: uppercase;
        color: var(--subtle-text-color);
      }

      h6 {
        letter-spacing: normal;
      }

      .center-dots {
        position: relative;
        width: 670px;
        max-width: 670px;

        .dots-v-center {
          height: 60px;
          border-right: 1px dashed #171e2a;
          width: 0;
          margin: 0 auto;
          margin-bottom: 2px !important;
        }

        .bottom-dots {
          position: absolute;
          height: 23px;
          border: 1px dashed #171e2a;
          border-bottom: none !important;
          left: ${props => props.range && props.range[0] && props.range[0]}%;
          width: ${props =>
            props.range &&
            props.range[0] &&
            props.range[1] - props.range[0] + 2}%;
        }
      }

      .left {
        text-align: left;

        .left-v-dots {
          border-left: 1px dashed #171e2a;
          height: 40px;
          margin-left: 8px;
        }
      }

      .right {
        text-align: right;

        .right-v-dots {
          border-right: 1px dashed #171e2a;
          height: 40px;
          margin-right: 8px;
        }
      }

      .label {
        margin-bottom: 0;
      }
    }

    .result-title {
      font-size: 14.4px;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 2px;
      margin-bottom: 16px;
      color: var(--subtle-text-color);
    }

    .amount-range {
      margin-bottom: 24px;

      @media (max-width: 768px) {
        font-size: 34px;
      }
    }

    .disclaimer {
      margin: 0 auto;
      font-weight: normal;
      font-size: 14.4px;
      color: var(--subtle-text-color);
      max-width: 668px;
    }

    .result-card {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 24px;
      box-shadow: 0 8px 80px rgba(106, 114, 158, 0.2);
      border-radius: 8px;
      margin-bottom: 16px;
      background-color: var(--white);

      .c-key {
        font-weight: bold;
        font-size: 14.4px;
        letter-spacing: 2px;
        text-transform: uppercase;
        margin-bottom: 0;
      }

      .c-value {
        margin-bottom: 0;

        @media (min-width: 768px) and (max-width: 991px) {
          font-size: 18px;
        }
      }
    }

    .action-buttons {
      display: flex;
      justify-content: flex-start;
      padding-bottom: 32px;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      a,
      button {
        text-transform: none;
        font-weight: bold;
        font-size: 12.8px;
        padding-left: 16px;
        padding-right: 16px;

        @media (max-width: 768px) {
          margin-bottom: 8px;
        }

        &:first-of-type {
          margin-right: 12px;

          @media (max-width: 768px) {
            margin-right: 0;
          }
        }

        svg {
          margin-right: 8px;
        }
      }
    }
  }
`;

export const ConversionPanel = styled.div`
  position: relative;
  min-height: 460px;
  padding: 72px 0;
  color: var(--white);
  background-image: url(${conversionBgImage});
  background-size: cover;
  background-repeat: no-repeat;

  .roi-wrapper {
    max-width: 770px;
    margin: 0 auto;

    .roi-title {
      margin-bottom: 32px;
      line-height: 44px;
    }

    .quote {
      font-weight: 500;
      margin-bottom: 12px;
    }

    .name {
      font-size: 14.4px;
      margin-bottom: 32px;
    }
  }
`;
